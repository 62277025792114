import React, {useEffect, useState} from 'react';
import questionImg from '../../static/images/illustrations/Question.png';

const Question = props => {

	const [isAdd, setIsAdd] = useState(props.addBtn);

    const [opened, setOpened] = useState(false);

    const addToLocalStorage = () => {
		if (typeof window !== `undefined`) {
			let localStorageList = JSON.parse(localStorage.getItem('sporsmal'));
			let questions = [];
			if(Array.isArray(localStorageList)) {
				questions = questions.concat(localStorageList)
			}
			questions.push(props.data)
			const stringList = JSON.stringify(questions);
			localStorage.setItem('sporsmal', stringList);
			setIsAdd(false);
		}
	}

	const remove = () => {
		let newList = props.list.filter(it => it.question !== props.data.question);
		props.setList(newList)
	}


	const isNotInLocalStorage = (data) => {
		if (typeof window !== `undefined`) {
			let localStorageList = JSON.parse(localStorage.getItem('sporsmal'));
			let questions = [];
			if(Array.isArray(localStorageList)) {
				questions = questions.concat(localStorageList)
			}
			for(let i in questions) {
				if(questions[i].question === data.question) {
					return false
				}
			}
		}
		return true;
	}

    return (
			<div className="d-flex ddd">
				<div className="d-flex quiz-q bg-white w-100">
					<div className="d-flex align-items-center mt-n3">
						<div className="quiz-number">{props?.index}.</div>
					</div>
					<div className="qq text-center">
						<div className="question">{props?.data?.question}</div>
						{props?.data?.alternatives &&
						<ul className="alternatives">
							{props?.data?.alternatives && props.data.alternatives.map(alternative => (
								<li style={{"listStyle": "auto", "fontWeight":"bold"}}>{alternative}</li>
							))}
						</ul>}
						{!opened && <div className="mt-2" onClick={setOpened}>
							<div className="q-title-sm btn-link cursor-pointer">Vis svar</div>
						</div>}
						{opened && <div className="mt-2" onClick={() => setOpened(false)}>
							<div className="q-title-sm btn-link cursor-pointer">Skjul svar</div>
						</div>}
						<div className={`${opened ? "open" : ""} answer mt-1`}>{props?.data?.answer}</div>
					</div>
					{/* Legg til funksjon for å legge til spm i mine spm her */}
					<div className="buttons">
						<div className={"w-50"}>
							{isAdd && isNotInLocalStorage(props.data) && <div className="add-button">
								<button onClick={addToLocalStorage} className="btn-primary btn-circle" title="Legg til i mine spørsmål">
									<span className="btn-cross">+</span>
								</button>
							</div>}
						</div>
						<div className={"w-50"}>
							{props.removeBtn && <div className="add-button">
								<button onClick={remove} className="btn-primary btn-circle" title="Legg til i mine spørsmål">
									<span className="btn-line">-</span>
								</button>
							</div>}
						</div>
					</div>
				</div>
				<div>
					<img src={questionImg} className="qq-question-img"/>
				</div>
			</div>
    );
};

export default Question;
